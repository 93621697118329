// Filters 
// The number of filter options 
$filter-number: 8 !default;

%filter-checked-nav { 
  background: $primary-color;
  color: $light-color;
}

%filter-checked-body { 
  display: none;
}

.filter {
  .filter-nav {
    margin: $layout-spacing 0;
  }

  .filter-body {
    display: flex;
    flex-wrap: wrap;
  }

  .filter-tag {
    @for $i from 0 through ($filter-number) {
      &#tag-#{$i}:checked ~ .filter-nav .chip[for="tag-#{$i}"] {
        @extend %filter-checked-nav;
      }
    }

    @for $i from 1 through ($filter-number) {
      &#tag-#{$i}:checked ~ .filter-body .filter-item:not([data-tag~="tag-#{$i}"]) {
        @extend %filter-checked-body;
      }
    }
  }
}
